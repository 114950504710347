import moment from 'moment';
import NetworkService, { Endpoint } from "./NetworkService"
import { IChatNewMessage } from '@/interfaces';

export default class ChatService {
	network
	static DEFAULT_MESSAGE = 'Esta conversa foi criada automaticamente\ncom psi após a confirmação do agendamento'
	constructor() {
		this.network = new NetworkService()
	}
	sendMessage(params: IChatNewMessage) {
		return this.network.post(Endpoint.chatMessages, params)
	}
	getMessages(chatId: any) {
		return this.network.get(`${Endpoint.chatMessages}`, { appointment_chat_id: chatId })
	}
}
